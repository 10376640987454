import { CorporateContactJsonLd } from 'next-seo';
import { ContactPoint } from 'next-seo/lib/types';
import dynamic from 'next/dynamic';
import { FC, PropsWithChildren, useMemo } from 'react';

import Meta, { MetaProps } from '~components/common/Meta';
import Footer from '~components/ui/Footer';
import Header, { HeaderProps } from '~components/ui/Header';
import useGlobalState from '~utils/hooks/useGlobalState';

const BubbleStack = dynamic(() => import('../BubbleStack'), {
  ssr: false,
}) as typeof import('../BubbleStack').default;

interface Props {
  meta: MetaProps;
  headerType?: HeaderProps['type'];
}

const Layout: FC<PropsWithChildren<Props>> = ({ meta, children, headerType = 'translucent' }) => {
  const [sessionResumed] = useGlobalState<boolean>('sessionResumed');
  const jsonLdForContact = useMemo((): ContactPoint[] | undefined => {
    return process.env.NEXT_PUBLIC_CONTACT_PHONENUMBERS?.split('|').map(
      (phoneNumbers: string): ContactPoint => {
        return {
          telephone: phoneNumbers,
          contactType: 'customer service',
          areaServed: 'MX',
          availableLanguage: 'Spanish',
        };
      },
    );
  }, []);
  return (
    <div className="flex min-h-screen flex-col">
      <CorporateContactJsonLd
        name="Citicinemas"
        url={process.env.NEXT_PUBLIC_APP_URL || 'https://citicinemas.com'}
        logo={`${process.env.NEXT_PUBLIC_APP_URL}/img/logo.png`}
        contactPoint={jsonLdForContact || []}
      />
      <Meta {...meta} />
      <Header type={headerType} />
      <main className="main">{children}</main>
      {sessionResumed && <BubbleStack />}
      <Footer />
    </div>
  );
};

export default Layout;
