import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import MainMenu from '../MainMenu';
import s from './Header.module.css';

export interface HeaderProps {
  type: 'translucent' | 'opaque';
}

const Header: FC<HeaderProps> = ({ type }) => {
  return (
    <header className={`${s.root} ${s[type]}`}>
      <div className={s.content}>
        <h1>
          <Link href="/" tabIndex={1} className={s.logo}>
            <Image
              src="/img/logo.svg"
              width={92}
              height={48}
              alt="Citicinemas: La experiencia y emoción del cine"
              className="object-contain"
              priority
            />
          </Link>
        </h1>
        <MainMenu />
      </div>
    </header>
  );
};

export default Header;
