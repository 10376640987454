import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FC, MouseEventHandler, useContext } from 'react';

import { ModalContext } from '~components/ModalProvider';
import type { LoginFormType } from '~components/User/LoginForm';
import type { ModalType } from '~components/ui/Modal';
import useGlobalState from '~utils/hooks/useGlobalState';

import { User } from '~types';

import s from './MainMenu.module.css';

const AuthenticatedUser = dynamic(() => import('~components/ui/AuthenticatedUser'), {
  ssr: false,
}) as any;
const Modal = dynamic(() => import('~components/ui/Modal'), { ssr: false }) as ModalType;
const LoginForm = dynamic(() => import('~components/User/LoginForm'), {
  ssr: false,
}) as LoginFormType;
const SignUpFlow = dynamic(() => import('~components/User/SignUpFlow'), {
  ssr: false,
}) as any;

const MainMenu: FC = () => {
  const [user] = useGlobalState<User>('user');
  const context = useContext(ModalContext);
  const loggedUser = !!user?.id;

  const handleLogin: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    context.setModal('login');
  };
  const handleSignUp: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    context.setModal('signup');
  };

  const handleClose = () => {
    context.setModal(null);
    const menuController = document.getElementById('MainMenuController') as HTMLInputElement | null;
    if (menuController) menuController.checked = false;
  };

  return (
    <div className={loggedUser ? `${s.root} ${s.authenticated}` : s.root}>
      <input type="checkbox" className={s.controller} id="MainMenuController" />
      <div className={s.content}>
        <label className={s.overlay} htmlFor="MainMenuController" />

        {loggedUser && (
          <div className={s.authUser}>
            <AuthenticatedUser user={user} />
          </div>
        )}
        <div className={s.userMenu}>
          {!loggedUser ? (
            <>
              <button className="btn btn-link text-white" onClick={handleLogin}>
                Iniciar sesión
              </button>
              <button className="btn btn-primary" onClick={handleSignUp}>
                Registrarse
              </button>
            </>
          ) : (
            <a href="/logout" className={s.logout}>
              Cerrar sesión
            </a>
          )}
        </div>
        <label className={s.trigger} htmlFor="MainMenuController" tabIndex={2}>
          <span className={s.icon}>
            <span />
            <span />
            <span />
          </span>
          <ul className={s.links}>
            {loggedUser && (
              <li>
                <Link href="/perfil" className="text-white">
                  Perfil
                </Link>
              </li>
            )}
            <li>
              <Link href="/" className="text-white">
                Cartelera
              </Link>
            </li>
            <li>
              <Link href="/promociones" className="text-white">
                Promociones
              </Link>
            </li>
            <li>
              <Link href="/proximos-estrenos" className="text-white">
                Próximos estrenos
              </Link>
            </li>
            {!loggedUser && (
              <li>
                <Link href="/beneficios-citipremia" className="text-white">
                  CitiPremia
                </Link>
              </li>
            )}
            {loggedUser && (
              <li className="hidden md:block">
                <a href="/logout" className="text-white">
                  Cerrar sesión
                </a>
              </li>
            )}
          </ul>
        </label>
      </div>
      {context.modal === 'login' && (
        <Modal open onClose={handleClose}>
          <LoginForm onSuccess={handleClose} />
        </Modal>
      )}
      {context.modal === 'signup' && (
        <Modal open onClose={handleClose}>
          <SignUpFlow
            email={context?.data?.email}
            confirmationNumber={context?.data?.confirmationNumber}
            onSuccess={handleClose}
          />
        </Modal>
      )}
    </div>
  );
};

export default MainMenu;
